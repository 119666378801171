<template>
  <div>
      <v-navigation-drawer
      dark
      v-model="drawer"
      app>
      <v-list-item class="text-center">
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Night App
          </v-list-item-title>
          <v-list-item-subtitle>
            Business
          </v-list-item-subtitle>
        </v-list-item-content>
        <img class="imagem-nav-bar" src="@/assets/logo.png">
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to='item.to'
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
         @click.prevent="EfetuarLogout"
        >
           <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar class="nav-bar" fixed dark>
      <v-app-bar-nav-icon 
      color="#fff"
      @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>Night App</v-app-bar-title>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({ 
    drawer: null,
    items: [
       //{ title: 'Dashboard', icon: 'mdi-view-dashboard', to:'/dashboard' },
       { title: 'Financeiro', icon: 'mdi-cash-multiple', to:'/financeiro' },
       { title: 'Evento', icon: 'mdi-calendar-check-outline', to:'/evento' },
       { title: 'Bilheteria', icon: 'mdi-ticket-confirmation' , to:'/bilheteria'},
       { title: 'Restaurantes', icon: 'mdi-basket' , to:'/restaurante'},
       { title: 'Permutas', icon: 'mdi-account-switch-outline' , to:'/permuta'},
    ],
  }),
  methods:{
    ...mapActions('auth', ['ActionDoLogout']),

     async  EfetuarLogout(){
      await this.ActionDoLogout()

    }
  }
}
</script>
<style>
.imagem-nav-bar{
  justify-content: center;
}
</style>
